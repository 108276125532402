import React from "react";
import "./index.less";
import CardImage from "../../../../components/CardImage";

const CardTable: React.FC<{
  publicCards?: number[];
}> = ({ publicCards = [] }) => {
  while (publicCards.length < 5) {
    publicCards.push(0);
  }
  return (
    <div className="card-table">
      <div className="table-cards">
        {publicCards.map((code, index) => {
          return (
            <div className="table-card-item" key={index}>
              <CardImage imageCode={code} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardTable;
