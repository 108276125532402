import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import PlayRoomPage from "./pages/PlayRoomPage";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<>All Is Over...</>} />
            <Route path="/game" element={<LandingPage />} />
            <Route path="/room" element={<PlayRoomPage />} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
