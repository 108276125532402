import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import { useNavigate } from "react-router-dom";
import PlayerMain from "./components/PlayerMain";
import CardTable from "./components/CardTable";
import { updateRoomData } from "../../requests";
import { message } from "antd";
import { ErrorCodeMsg, ResponseCode } from "../../types/base-request";
import { GameRoomData } from "../../types/texas-poker";
import { LocalRoomUserInfo, LocalStorageKeys } from "../../types/localStorages";
import PlayerCustom from "./components/PlayerCustom";

const PlayRoomPage: React.FC = () => {
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState<GameRoomData | undefined>(undefined);
  const [localRoomUserInfo, setLocalRoomUserInfo] = useState<
    LocalRoomUserInfo | undefined
  >(undefined);

  const versionRef = useRef(0);

  // 进入房间后开始轮询状态
  useEffect(() => {
    // 从本地缓存读取房间用户信息，没有则返回 landing 页
    const localRoomUserInfoString = localStorage.getItem(
      LocalStorageKeys.ROOM_USER_INFO
    );
    if (!localRoomUserInfoString) {
      navigate("/");
      return;
    }
    const localRoomUserInfo: LocalRoomUserInfo = JSON.parse(
      localRoomUserInfoString
    );
    if (
      localRoomUserInfo.roomId === undefined ||
      localRoomUserInfo.userId === undefined
    ) {
      navigate("/");
      return;
    }
    setLocalRoomUserInfo(localRoomUserInfo);

    // 本地有房间用户数据，开始轮询
    let pollingSatatus = true;
    const longPolling = async () => {
      if (!pollingSatatus) return;
      try {
        const res = (
          await updateRoomData({
            dataVersion: versionRef.current,
            roomId: localRoomUserInfo.roomId,
            userId: localRoomUserInfo.userId,
          })
        ).data;
        switch (res.code) {
          // 正常请求
          case 200:
            if (res.data && res.data.updated) {
              setRoomData(res.data.roomData);
              versionRef.current = res.data.roomData?.dataVersion || 0;
            }
            longPolling();
            break;
          // 房间状态失效，返回 landing 页面
          case ResponseCode.GAME_ROOM_NO_EXIST:
            message.error(res.msg || ErrorCodeMsg[res.code]);
            // 清空本地缓存
            localStorage.removeItem(LocalStorageKeys.ROOM_USER_INFO);
            setTimeout(() => {
              navigate("/");
            }, 1500);
            break;
          // 其他错误，继续轮询
          default:
            // 稍微延迟一下，防止接口报错后连续请求
            setTimeout(() => {
              longPolling();
            }, 2000);
            break;
        }
      } catch (e) {
        console.log(e);
        // 请求错误，继续轮询（稍微延迟一下，防止接口报错后连续请求）
        setTimeout(() => {
          longPolling();
        }, 2000);
      }
    };
    // 开始轮询状态
    longPolling();

    // 卸载时清除 polling
    return () => {
      pollingSatatus = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="play-room-page">
      <div className="player-customs-container">
        <PlayerCustom
          playersInfo={roomData?.playersInfo}
          playersRoundData={roomData?.gameRoundData?.playersRoundData}
          playerCards={roomData?.gameRoundData?.palyerCards}
          mainUserId={localRoomUserInfo?.userId}
        />
      </div>

      <div className="card-table-container">
        <CardTable publicCards={roomData?.gameRoundData?.publicCards} />
      </div>

      <div className="player-main-container">
        <PlayerMain roomData={roomData} localRoomUserInfo={localRoomUserInfo} />
      </div>
    </div>
  );
};

export default PlayRoomPage;
