import React, { useCallback, useState } from "react";
import "./index.less";
import { Button, Popconfirm, message } from "antd";
import CardImage from "../../../../components/CardImage";
import {
  GameRoomData,
  PlayerRoundData,
  RoomStatusType,
} from "../../../../types/texas-poker";
import {
  LocalRoomUserInfo,
  LocalStorageKeys,
} from "../../../../types/localStorages";
import { PlayerRoleTag, PlayerStatusTag } from "../PlayerTag";
import PlayerActions from "../PlayerActions";
import { addBot, leaveRoom, nextRound, startGame } from "../../../../requests";
import { ResponseCode } from "../../../../types/base-request";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import SetConfigsModel from "../SetConfigsModal";

const PlayerMain: React.FC<{
  roomData?: GameRoomData;
  localRoomUserInfo?: LocalRoomUserInfo;
}> = ({ roomData, localRoomUserInfo }) => {
  const userId = localRoomUserInfo?.userId || "";
  const playerInfo = (roomData?.playersInfo || []).find((item) => {
    return item.userId === userId;
  });
  const playerCards = (roomData?.gameRoundData?.palyerCards || {})[userId] || [
    0, 0,
  ];
  const playerRoundData: PlayerRoundData | undefined = (roomData?.gameRoundData
    ?.playersRoundData || {})[userId];

  const [actionAble, setActionAble] = useState(true);
  const [isConfigsModelShow, setConfigsModelShow] = useState(false);

  const navigate = useNavigate();

  const startGameClick = useCallback(
    _.debounce(async () => {
      if (!roomData) {
        message.error("当前无法操作！");
        return;
      }
      setActionAble(false);
      try {
        const res = (await startGame({ roomId: roomData.roomId })).data;
        if (res.code !== ResponseCode.SUCCESS) {
          message.error(res.msg || "当前无法操作");
        }
      } catch (e) {
        message.error("unknow error!");
      } finally {
        setActionAble(true);
      }
    }, 200),
    [roomData]
  );

  const nextRoundClick = useCallback(
    _.debounce(async () => {
      if (!roomData || !localRoomUserInfo) {
        message.error("当前无法操作！");
        return;
      }
      setActionAble(false);
      try {
        const res = (
          await nextRound({
            roomId: roomData.roomId,
            userId: localRoomUserInfo.userId,
          })
        ).data;
        if (res.code !== ResponseCode.SUCCESS) {
          message.error(res.msg || "当前无法操作");
        }
      } catch (e) {
        message.error("unknow error!");
      } finally {
        setActionAble(true);
      }
    }, 200),
    [roomData, localRoomUserInfo]
  );

  const roomConfigsClick = useCallback(() => {
    setConfigsModelShow(true);
  }, []);

  const leaveRoomClick = useCallback(
    _.debounce(async () => {
      if (!roomData || !localRoomUserInfo) {
        message.error("当前无法操作！");
        return;
      }
      setActionAble(false);
      try {
        const res = (
          await leaveRoom({
            roomId: roomData.roomId,
            userId: localRoomUserInfo.userId,
          })
        ).data;
        if (res.code !== ResponseCode.SUCCESS) {
          message.error(res.msg || "当前无法操作");
        } else {
          // 清除本地缓存并回到首页
          localStorage.removeItem(LocalStorageKeys.ROOM_USER_INFO);
          navigate("/");
        }
      } catch (e) {
        message.error("unknow error!");
      } finally {
        setActionAble(true);
      }
    }, 200),
    [roomData, localRoomUserInfo]
  );

  const addBotClick = useCallback(
    _.debounce(async () => {
      if (!roomData || !localRoomUserInfo) {
        message.error("当前无法操作！");
        return;
      }
      setActionAble(false);
      try {
        const res = (
          await addBot({
            roomId: roomData.roomId,
            userId: localRoomUserInfo.userId,
          })
        ).data;
        if (res.code !== ResponseCode.SUCCESS) {
          message.error(res.msg || "当前无法操作");
        }
      } catch (e) {
        console.log(e);
        message.error("当前无法操作");
      } finally {
        setActionAble(true);
      }
    }, 200),
    [localRoomUserInfo, roomData]
  );

  return (
    <div className="player-main">
      <div className="player-info">
        <div className="player-name">
          <span className="player-name-text">
            {localRoomUserInfo?.userName || ""}
          </span>
          <PlayerRoleTag
            playerRoundData={playerRoundData}
            playerInfo={playerInfo}
          />
        </div>
        <div className="player-money">{`$ ${playerInfo?.money || 0}`}</div>
        {roomData &&
          [RoomStatusType.PREPARATION, RoomStatusType.GAME_OVER].indexOf(
            roomData.status
          ) !== -1 &&
          roomData.ownerId === localRoomUserInfo?.userId && (
            <div className="player-btn">
              <Button disabled={!actionAble} onClick={startGameClick}>
                Start Game
              </Button>
            </div>
          )}
        {roomData &&
          roomData.status === RoomStatusType.ROUND_FINISH &&
          roomData.ownerId === localRoomUserInfo?.userId && (
            <div className="player-btn">
              <Button disabled={!actionAble} onClick={nextRoundClick}>
                Next Round
              </Button>
            </div>
          )}
        {roomData &&
          roomData.ownerId === localRoomUserInfo?.userId &&
          [RoomStatusType.PREPARATION, RoomStatusType.GAME_OVER].includes(
            roomData.status
          ) && (
            <div className="player-btn">
              <Button disabled={!actionAble} onClick={addBotClick}>
                Add Bot
              </Button>
            </div>
          )}
        {roomData &&
          roomData.ownerId === localRoomUserInfo?.userId &&
          [RoomStatusType.PREPARATION, RoomStatusType.GAME_OVER].includes(
            roomData.status
          ) && (
            <div className="player-btn">
              <Button disabled={!actionAble} onClick={roomConfigsClick}>
                Room Configs
              </Button>
            </div>
          )}
        <div className="player-btn">
          <Popconfirm
            title="Leave Room"
            description="Are you sure to leave room?"
            onConfirm={leaveRoomClick}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={!actionAble}>Leave Room</Button>
          </Popconfirm>
        </div>
      </div>
      <div className="player-cards">
        {playerCards.map((item, index) => {
          return (
            <div className="player-card-item" key={index}>
              <CardImage imageCode={item} />
            </div>
          );
        })}
      </div>
      <div className="player-bet-info-and-action">
        <div className="player-bet-status">
          <span className="player-bet-money-text">
            bet：${playerRoundData?.betMoney || 0}
          </span>
          <PlayerStatusTag
            playerRoundData={playerRoundData}
            playerInfo={playerInfo}
          />
        </div>
        <div className="player-actions-container">
          {roomData?.gameRoundData?.playersRoundData[
            localRoomUserInfo?.userId || ""
          ]?.isActive === true && (
            <PlayerActions
              playerInfo={playerInfo}
              playerRoundData={playerRoundData}
              raiseTotalMoney={roomData?.gameRoundData?.raiseTotalMoney}
              localRoomUserInfo={localRoomUserInfo}
              actionAble={actionAble}
              setActionAble={setActionAble}
            />
          )}
        </div>
      </div>
      {roomData && localRoomUserInfo && (
        <SetConfigsModel
          isModalShow={isConfigsModelShow}
          setModalShow={setConfigsModelShow}
          initConfigs={roomData.roomConfigs}
          userId={localRoomUserInfo.userId}
          roomId={roomData.roomId}
        />
      )}
    </div>
  );
};

export default PlayerMain;
