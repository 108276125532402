import { Modal, Form, InputNumber, Input, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useCallback, useState } from "react";
import { RoomConfigs } from "../../../../types/texas-poker";
import { setRoomConfigs } from "../../../../requests";

export const SetConfigsModel: React.FC<{
  isModalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  initConfigs: RoomConfigs;
  roomId: string;
  userId: string;
}> = ({ isModalShow, setModalShow, initConfigs, roomId, userId }) => {
  const [form] = useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const values: RoomConfigs = await form.validateFields();
      try {
        const res = (
          await setRoomConfigs({
            roomConfigs: values,
            roomId,
            userId,
          })
        ).data;
        if (res.code !== 200) {
          message.error(res.msg || "设置失败，请重试");
        } else {
          message.success("设置成功！");
          setModalShow(false);
        }
      } catch (e) {
        console.log(e);
        message.error("设置失败，请重试");
      }
    } catch (info) {
      console.log("表单校验失败", info);
      message.error("设置失败，请重试");
    } finally {
      setConfirmLoading(false);
    }
  }, [form, setModalShow, roomId, userId]);

  const handleCancel = useCallback(() => {
    setModalShow(false);
    form.resetFields();
  }, [form, setModalShow]);

  return (
    <>
      <Modal
        title="Room Configs Setting"
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form form={form} autoComplete="off" initialValues={initConfigs}>
          <Form.Item
            name="initMoney"
            label="initMoney"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={1} />
          </Form.Item>
          <Form.Item
            name="bigBlindBet"
            label="bigBlindBet"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item
            name="smallBlindBet"
            label="smallBlindBet"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item
            name="minPlayerNum"
            label="minPlayerNum"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} max={8} min={2} />
          </Form.Item>
          <Form.Item
            name="maxPlayerNum"
            label="maxPlayerNum"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} max={8} min={2} />
          </Form.Item>
          <Form.Item
            name="waitingTime"
            label="waitingTime(s)"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} min={1} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SetConfigsModel;
