// 房间状态
export enum RoomStatusType {
  // 准备中，未开始
  PREPARATION = "PREPARATION",
  // 游戏中
  IN_ROUND = "IN_ROUND",
  // 本轮结束，显示结果，等待房主发下一轮牌
  ROUND_FINISH = "ROUND_FINISH",
  // 游戏结束，等待房主重新开始（重置筹码）
  GAME_OVER = "GAME_OVER",
}

// 房间中用户信息
export interface PlayerInfo {
  userId: string;
  userName: string;
  money: number;
  isOwner: boolean;
  isLeaved: boolean;
  isOut: boolean;
  isBot: boolean;
}

// 本轮用户游戏信息
export interface PlayerRoundData {
  userId: string;
  isDealer: boolean;
  isBigBlind: boolean;
  isSmallBlind: boolean;
  isActive: boolean;
  betMoney: number;
  isFall: boolean;
  isWin: boolean;
  isAllIn: boolean;
  cardsResult?: CardsResult;
}

// 本轮游戏信息
export interface GameRoundData {
  playersRoundData: { [userId: string]: PlayerRoundData };
  dealerId: string;
  // 第一个操作者或起raise者，转到这本轮结束
  raiseUserId: string;
  // 起 reise 者是否已经行动过了
  // 由于初始的 RaiseUserId 是枪口位，但枪口位实际上还没有行动，所以初始设为 false，枪口位行动后改为 true，其他 raise 者不需要关注
  isRaiseUserActed: boolean;
  // raise 后的总筹码
  raiseTotalMoney: number;
  publicCards: number[];
  palyerCards: { [userId: string]: number[] };
  // 是否需要开牌
  isShowCards: boolean;
}

// 房间配置信息
export interface RoomConfigs {
  maxPlayerNum: number;
  initMoney: number;
  bigBlindBet: number;
  smallBlindBet: number;
  minPlayerNum: number;
  // 暂时没做
  waitingTime: number;
}

// 完整的游戏房间信息
export interface GameRoomData {
  dataVersion: number;
  roomId: string;
  status: RoomStatusType;
  ownerId: string;
  // 房间配置信息
  roomConfigs: RoomConfigs;
  // 房间用户信息
  playersInfo: PlayerInfo[];
  // 本轮游戏数据
  gameRoundData?: GameRoundData;
}

export const DEFAULT_ROOM_CONFIGS: RoomConfigs = {
  maxPlayerNum: 8,
  initMoney: 200,
  bigBlindBet: 10,
  smallBlindBet: 5,
  minPlayerNum: 2,
  waitingTime: 120,
};

export enum PlayerActionTypes {
  CHECK = "CHECK",
  CALL = "CALL",
  RAISE = "RAISE",
  FALL = "FALL",
  ALLIN = "ALLIN",
}

export enum CardsType {
  // 同花顺
  STRAIGHT_FLUSH = "STRAIGHT_FLUSH",
  // 四条
  FOUR_KIND = "FOUR_KIND",
  // 葫芦
  FULL_HOUSE = "FULL_HOUSE",
  // 同花
  FLUSH = "FLUSH",
  // 顺子
  STRAIGHT = "STRAIGHT",
  // 三条
  THREE_KIND = "THREE_KIND",
  // 两对
  TWO_PAIR = "TWO_PAIR",
  // 一对
  ONE_PAIR = "ONE_PAIR",
  // 高牌
  HIGH_CARD = "HIGH_CARD",
}

export const CardsTypeSort = [
  CardsType.STRAIGHT_FLUSH,
  CardsType.FOUR_KIND,
  CardsType.FULL_HOUSE,
  CardsType.FLUSH,
  CardsType.STRAIGHT,
  CardsType.THREE_KIND,
  CardsType.TWO_PAIR,
  CardsType.ONE_PAIR,
  CardsType.HIGH_CARD,
];
export interface CardsResult {
  cardsType: CardsType;
  cardsDetail: {
    // 同花顺最大牌
    straightFlushMax?: number;
    // 四条
    fourKind?: number;
    // 葫芦的3张
    fullHouseThree?: number;
    // 葫芦的2张
    fullHouseTwo?: number;
    // 同花的顺序
    flushList?: number[];
    // 顺子的最大牌
    straightMax?: number;
    // 三条
    threeKind?: number;
    // 两对的两个值
    twoPairList?: number[];
    // 一对
    onePair?: number;
    // 其他牌排序
    otherCards?: number[];
  };
}
