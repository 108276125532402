import React, { useEffect } from "react";
import "./index.less";
import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { enterRoom } from "../../requests";
import { LocalRoomUserInfo, LocalStorageKeys } from "../../types/localStorages";

type FieldType = {
  roomId?: number;
  userName?: string;
};

const validateRoomId = (value: string) => {
  if (!value || value.length !== 4) {
    return Promise.reject(new Error("请输入四位数字房间 id"));
  }
  for (const char of value) {
    if (!/^\d$/.test(char)) {
      return Promise.reject(new Error("请输入四位数字房间 id"));
    }
  }
  return Promise.resolve();
};

const LandingPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // 本地有房间信息，直接跳转到 room
  useEffect(() => {
    const localRoomUserInfo = localStorage.getItem(
      LocalStorageKeys.ROOM_USER_INFO
    );
    if (localRoomUserInfo) {
      navigate("/room");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const userName = values.userName;
      const roomId = values.roomId;
      try {
        const res = (
          await enterRoom({
            userName: userName,
            roomId: roomId,
          })
        ).data;
        if (res.code === 200 && res.data) {
          const localRoomUserInfo: LocalRoomUserInfo = {
            roomId: roomId,
            userId: res.data.userId,
            userName: userName,
          };
          localStorage.setItem(
            LocalStorageKeys.ROOM_USER_INFO,
            JSON.stringify(localRoomUserInfo)
          );
          navigate("/room");
        } else {
          message.error(res.msg || "进入房间失败，请重试！");
        }
      } catch (e) {
        console.log(e);
        message.error("进入房间失败，请重试！");
      }
    } catch (info) {
      console.log("校验失败", info);
      message.error("进入房间失败，请重试！");
    }
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        autoComplete="off"
        form={form}
      >
        <Form.Item<FieldType>
          label="房间 id"
          name="roomId"
          rules={[
            {
              validator: (_, value) => validateRoomId(value),
            },
          ]}
        >
          <Input placeholder="四位数字房间 id" />
        </Form.Item>

        <Form.Item<FieldType>
          label="用户名"
          name="userName"
          rules={[{ required: true, message: "请输入用户 id" }]}
        >
          <Input placeholder="用户名" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" onClick={handleSubmit}>
            进入
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LandingPage;
