import { RequestResType } from "../types/base-request";
import {
  AddBotReq,
  AddBotRes,
  EnterRoomReq,
  EnterRoomRes,
  LeaveRoomReq,
  LeaveRoomRes,
  NextRoundReq,
  NextRoundRes,
  PlayerActionReq,
  PlayerActionRes,
  SetRoomConfigsReq,
  SetRoomConfigsRes,
  StartGameReq,
  StartGameRes,
  UpdateRoomDataReq,
  UpdateRoomDataRes,
} from "../types/texas-poker/requests";
import api from "./base-api";

export const enterRoom = async (
  data: EnterRoomReq
): Promise<{ data: RequestResType<EnterRoomRes> }> => {
  return api.post("/texas-poker/enterRoom", data);
};

export const updateRoomData = async (
  data: UpdateRoomDataReq
): Promise<{ data: RequestResType<UpdateRoomDataRes> }> => {
  return api.post("/texas-poker/updateRoomData", data);
};

export const startGame = async (
  data: StartGameReq
): Promise<{ data: RequestResType<StartGameRes> }> => {
  return api.post("/texas-poker/startGame", data);
};

export const nextRound = async (
  data: NextRoundReq
): Promise<{ data: RequestResType<NextRoundRes> }> => {
  return api.post("/texas-poker/nextRound", data);
};

export const playerAction = async (
  data: PlayerActionReq
): Promise<{ data: RequestResType<PlayerActionRes> }> => {
  return api.post("/texas-poker/playerAction", data);
};

export const leaveRoom = async (
  data: LeaveRoomReq
): Promise<{ data: RequestResType<LeaveRoomRes> }> => {
  return api.post("/texas-poker/leaveRoom", data);
};

export const setRoomConfigs = async (
  data: SetRoomConfigsReq
): Promise<{ data: RequestResType<SetRoomConfigsRes> }> => {
  return api.post("/texas-poker/setRoomConfigs", data);
};

export const addBot = async (
  data: AddBotReq
): Promise<{ data: RequestResType<AddBotRes> }> => {
  return api.post("/texas-poker/addBot", data);
};
