import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./index.less";
import { Button, InputNumber, Slider, message } from "antd";
import {
  PlayerActionTypes,
  PlayerInfo,
  PlayerRoundData,
} from "../../../../types/texas-poker";
import { playerAction } from "../../../../requests";
import { LocalRoomUserInfo } from "../../../../types/localStorages";
import { ResponseCode } from "../../../../types/base-request";
import _ from "lodash";

const PlayerActions: React.FC<{
  playerRoundData?: PlayerRoundData;
  playerInfo?: PlayerInfo;
  raiseTotalMoney?: number;
  localRoomUserInfo?: LocalRoomUserInfo;
  actionAble: boolean;
  setActionAble: Dispatch<SetStateAction<boolean>>;
}> = ({
  playerRoundData,
  playerInfo,
  raiseTotalMoney = 0,
  localRoomUserInfo,
  actionAble,
  setActionAble,
}) => {
  const [minRaiseMoney, setMinRaiseMoney] = useState(0);

  const [raiseMoney, setRaiseMoney] = useState(minRaiseMoney);
  useEffect(() => {
    const minRaiseMoney =
      playerRoundData &&
      playerInfo &&
      raiseTotalMoney - playerRoundData.betMoney >= 0
        ? raiseTotalMoney - playerRoundData.betMoney
        : 0;
    setMinRaiseMoney(minRaiseMoney);
    setRaiseMoney(minRaiseMoney);
  }, [playerInfo, playerRoundData, raiseTotalMoney]);

  const playerActionClick = useCallback(
    _.debounce(async (actionType: PlayerActionTypes) => {
      if (!localRoomUserInfo) {
        message.error("当前无法操作！");
        return;
      }
      setActionAble(false);
      try {
        const res = (
          await playerAction({
            roomId: localRoomUserInfo.roomId,
            userId: localRoomUserInfo.userId,
            actionType: actionType,
            raiseMoney: raiseMoney,
          })
        ).data;
        if (res.code !== ResponseCode.SUCCESS) {
          message.error(res.msg || "当前无法操作");
        }
      } catch (e) {
        message.error("unknow error!");
      } finally {
        setActionAble(true);
      }
    }, 200),
    [localRoomUserInfo, raiseMoney, setActionAble]
  );

  return (
    <div className="player-actions">
      {playerRoundData && raiseTotalMoney === playerRoundData.betMoney && (
        // Check: raiseTotal 和当前筹码相同
        <div className="action-btn">
          <Button
            className="btn"
            disabled={!actionAble}
            onClick={() => {
              playerActionClick(PlayerActionTypes.CHECK);
            }}
          >
            Check
          </Button>
        </div>
      )}

      {playerRoundData &&
        playerInfo &&
        raiseTotalMoney > playerRoundData.betMoney &&
        playerRoundData.betMoney + playerInfo.money >= raiseTotalMoney && (
          // Call: raiseTotal 大于当前筹码，且手中筹码足够跟注
          <div className="action-btn">
            <Button
              className="btn"
              disabled={!actionAble}
              onClick={() => {
                playerActionClick(PlayerActionTypes.CALL);
              }}
            >
              Call
            </Button>
          </div>
        )}

      {playerRoundData && playerInfo && (
        // AllIn: 随时可以 AllIn
        <div className="action-btn">
          <Button
            className="btn"
            disabled={!actionAble}
            onClick={() => {
              playerActionClick(PlayerActionTypes.ALLIN);
            }}
          >
            All In
          </Button>
        </div>
      )}

      {playerRoundData && playerInfo && (
        // Fall: 随时都可以 fall
        <div className="action-btn">
          <Button
            className="btn"
            disabled={!actionAble}
            onClick={() => {
              playerActionClick(PlayerActionTypes.FALL);
            }}
          >
            Fall
          </Button>
        </div>
      )}

      {playerRoundData &&
        playerInfo &&
        playerRoundData.betMoney + playerInfo.money >= raiseTotalMoney && (
          // Raise: 手中筹码+已下注raiseTotal 大于 raiseTotal
          <div className="raise-action-container">
            <div className="raise-money-change">
              <Slider
                min={minRaiseMoney}
                max={playerInfo?.money || 0}
                className="raise-money-slider"
                onChange={(newValue: number) => {
                  setRaiseMoney(newValue);
                }}
                railStyle={{ background: "#dfdfdf" }}
                value={raiseMoney || 0}
              />
              <InputNumber
                className="raise-money-input"
                value={raiseMoney || 0}
                onChange={(v) => {
                  if (v && v < minRaiseMoney) {
                    setRaiseMoney(minRaiseMoney);
                  } else if (v && playerInfo.money && v > playerInfo.money) {
                    setRaiseMoney(playerInfo.money);
                  } else {
                    setRaiseMoney(v || 0);
                  }
                }}
                max={playerInfo?.money || 0}
                min={minRaiseMoney}
              />
            </div>

            <Button
              className="raise-btn"
              disabled={!actionAble}
              onClick={() => {
                playerActionClick(PlayerActionTypes.RAISE);
              }}
            >
              Raise
            </Button>
          </div>
        )}
    </div>
  );
};

export default PlayerActions;
