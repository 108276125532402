import React from "react";
import Tag from "antd/es/tag";
import { PlayerInfo, PlayerRoundData } from "../../../../types/texas-poker";

export const PlayerRoleTag: React.FC<{
  playerRoundData?: PlayerRoundData;
  playerInfo?: PlayerInfo;
}> = ({ playerRoundData, playerInfo }) => {
  return (
    <>
      {playerRoundData?.isDealer && <Tag color="magenta">Dealer</Tag>}
      {playerRoundData?.isBigBlind && <Tag color="magenta">BB</Tag>}
      {playerRoundData?.isSmallBlind && <Tag color="magenta">SB</Tag>}
      {playerInfo?.isOut && <Tag color="#afafaf">Out</Tag>}
    </>
  );
};

export const PlayerStatusTag: React.FC<{
  playerRoundData?: PlayerRoundData;
  playerInfo?: PlayerInfo;
}> = ({ playerRoundData, playerInfo }) => {
  return (
    <>
      <>
        {playerRoundData?.isWin && <Tag color="success">Win</Tag>}
        {playerRoundData?.isAllIn && <Tag color="magenta">AllIn</Tag>}
        {playerRoundData?.isFall && <Tag color="warning">Fall</Tag>}
        {playerRoundData?.isActive && <Tag color="error">Active</Tag>}
        {playerInfo?.isLeaved && <Tag color="#afafaf">Leave</Tag>}
      </>
    </>
  );
};
