// todo: gpt 生成的简单封装，按需补充配置

import axios from "axios";

// const base_url = "http://localhost:3001";
const base_url = "https://texas-poker-api.cz-cloud.site";

// 创建 Axios 实例
const instance = axios.create({
  baseURL: base_url, // 基础 URL
  timeout: 30000, // 请求超时时间
  // headers: { "X-Custom-Header": "foobar" },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么，例如设置 token
    // config.headers.Authorization = `Bearer yourToken`;
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
