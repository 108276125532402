export type RequestResType<DataType = any> = {
  code: ResponseCode;
  data?: DataType extends undefined ? any : DataType;
  msg?: string;
};

export enum ResponseCode {
  SUCCESS = 200,

  // 请求参数不完整
  ERROR_COMMON = -1,
  // 房间不存在或已失效
  GAME_ROOM_NO_EXIST = 1001,
  // 房间已在游戏中
  GAME_ROOM_GAMING = 1002,
  // 房间已满
  GAME_ROOM_PLAYER_NUM_LIMIT = 1003,
  // 房间人数太少无法开始游戏
  GAME_ROOM_PLAYER_NOT_ENOUGH = 1004,

  // 当前无法执行该操作
  PLAYER_ACTION_NOT_ALLOW = 2001,
  // 需要房主权限才能操作（开始游戏、房间设置）
  NEED_OWNER_PERMISSION = 2002,

  // 未知错误
  UNKNOWN_ERROR = 9999,
}

export const ErrorCodeMsg: { [key in ResponseCode]?: string } = {
  [ResponseCode.ERROR_COMMON]: "请求参数不完整",
  [ResponseCode.GAME_ROOM_NO_EXIST]: "房间不存在或已失效",
  [ResponseCode.GAME_ROOM_GAMING]: "该房间已在游戏中",
  [ResponseCode.GAME_ROOM_PLAYER_NUM_LIMIT]: "该房间已满",
  [ResponseCode.GAME_ROOM_PLAYER_NOT_ENOUGH]: "房间人数不足",

  [ResponseCode.PLAYER_ACTION_NOT_ALLOW]: "当前无法执行该操作",
  [ResponseCode.NEED_OWNER_PERMISSION]: "只有房主才能进行该操作",

  [ResponseCode.UNKNOWN_ERROR]: "未知错误",
};
