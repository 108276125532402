import React from "react";
import "./index.less";
import CardImage from "../../../../components/CardImage";
import { PlayerInfo, PlayerRoundData } from "../../../../types/texas-poker";
import { PlayerRoleTag, PlayerStatusTag } from "../PlayerTag";

const PlayerCustomItem: React.FC<{
  playerInfo: PlayerInfo;
  playerRoundData?: PlayerRoundData;
  playerCard?: number[];
}> = ({ playerInfo, playerRoundData, playerCard = [0, 0] }) => {
  return (
    <div className="player-custom-item">
      <div className="player-info">
        <div className="player-name">{playerInfo?.userName || ""}</div>
        <div className="player-money">{`$ ${playerInfo?.money || 0}`}</div>
        <PlayerRoleTag
          playerRoundData={playerRoundData}
          playerInfo={playerInfo}
        />
      </div>

      <div className="player-cards">
        {playerCard.map((code, index) => {
          return (
            <div className="player-card-item" key={index}>
              <CardImage imageCode={code} />
            </div>
          );
        })}
      </div>
      <div className="player-bet-status">
        <div className="player-bet-money">
          bet：${playerRoundData?.betMoney || 0}
        </div>
        <PlayerStatusTag
          playerRoundData={playerRoundData}
          playerInfo={playerInfo}
        />
      </div>
    </div>
  );
};

export default PlayerCustomItem;
