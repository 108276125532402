import React from "react";
import "./index.less";
import PlayerCustomItem from "../PlayerCustomItem";
import { PlayerInfo, PlayerRoundData } from "../../../../types/texas-poker";

const PlayerCustom: React.FC<{
  playersInfo?: PlayerInfo[];
  playersRoundData?: { [userId: string]: PlayerRoundData };
  playerCards?: { [userId: string]: number[] };
  mainUserId?: string;
}> = ({ playersInfo = [], playersRoundData, playerCards, mainUserId = "" }) => {
  const mainUserIndex = playersInfo.findIndex(
    (item) => item.userId === mainUserId
  );
  // 排序后的 CustomPlayer
  const playersInfoSort: PlayerInfo[] = [];
  if (mainUserIndex !== -1) {
    playersInfoSort.push(
      ...playersInfo.slice(mainUserIndex + 1, playersInfo.length),
      ...playersInfo.slice(0, mainUserIndex)
    );
  }

  return (
    <div className="player-custom">
      {playersInfoSort.map((playerInfo) => {
        return (
          <>
            <PlayerCustomItem
              playerInfo={playerInfo}
              playerRoundData={playersRoundData?.[playerInfo?.userId]}
              playerCard={playerCards?.[playerInfo?.userId]}
              key={playerInfo.userId}
            />
          </>
        );
      })}
    </div>
  );
};

export default PlayerCustom;
