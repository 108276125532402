import React from "react";
import "./index.less";
import { Image } from "antd";

// cos 存储地址（已经改本地静态的了）
// const image_base_url = "https://common-imgs-1304819145.cos.ap-nanjing.myqcloud.com/texas-poker-cards/";

const CardImage: React.FC<{ imageCode: number }> = ({ imageCode }) => {
  return (
    <Image
      height="100%"
      preview={false}
      // src={`${image_base_url}${imageCode}.JPG`}
      src={require(`../../statics/${imageCode}.JPG`)}
      style={{ objectFit: "contain" }}
    />
  );
};

export default CardImage;
